.spanContainer {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  gap: 15px;
  color: var(--activityPrimary);
}
.error {
  color: var(--activityRed);
}

.spanContainer .header {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  white-space: nowrap;
  font-family: 'Roboto Mono', monospace;
}

.spanContainer .meta {
  display: flex;
  align-items: center; 
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 45px;
  font-family: 'Roboto Mono', monospace;
}

.spanContainer .meta .column {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  gap: 10px;
  font-family: 'Roboto Mono', monospace;
}

.spanContainer .meta .column .columnHeader {
  font-size: 12px;
  color: var(--activityBlackUltraLight);
  font-family: 'Roboto Mono', monospace;
}

.spanContainer.error .meta .column .columnHeader {
  font-size: 12px;
  color: var(--activityRed);
  font-family: 'Roboto Mono', monospace;
}

.spanContainer .meta .column .columnValue {
  font-size: 12px;
  font-family: 'Roboto Mono', monospace;
}

.spanContainer pre {
  width: 100%;
  white-space: break-spaces;
  margin-bottom: 0;
  max-width: 900px;
}

@media screen and (max-width: 900px) {
  .spanContainer pre {
    max-width: 100%;
  }
}
