.header {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}

.header .headerText {
  font-family: 'Roboto Mono', monospace !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  color: var(--activityBlackUltraLight);
  font-size: 12px;
  white-space: nowrap;
}