.logItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
  padding: 14px 20px 40px 40px;
}

.logItem > * {
  font-family: 'Roboto Mono', monospace !important;
}

.logItem.local {
  padding: 14px 20px 14px 40px;
}

.fullHeight {
  height: calc(100vh - 147px);
  justify-content: flex-end;
}

.logItem > div:nth-child(2) {
  margin-left: 10px;
}

.logItem > pre:nth-child(2) {
  margin-left: 10px;
}

.logItem:hover {
  background-color: var(--activityGreyDark);
}

.logItem.local:hover {
  background-color: transparent;
}
