.error {
  color: var(--activityRed)
}

.eventContainer {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  gap: 15px;
  color: var(--activityPrimary);
  font-family: 'Roboto Mono', monospace !important;
}

.eventContainer .eventHeader {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  white-space: pre-line;
  font-family: 'Roboto Mono', monospace !important;
}
