@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

.container {
  height: 100%;
  width: 100%;
  overflow: auto;
  font-family: 'Roboto Mono', monospace;
}

.container > * {
  font-family: 'Roboto Mono', monospace;
}

*::-webkit-scrollbar-thumb {
  background-color: #DDDDDD;
  border-radius: 4px;
}