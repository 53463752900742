.localHeader {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
  font-family: 'Roboto Mono', monospace !important;
}

.localHeader .localHeaderText {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  color: var(--activityBlackUltraLight);
  font-size: 14px;
  white-space: nowrap;
  font-family: 'Roboto Mono', monospace !important;
}

.localHeader .localHeaderText.localHeaderTextAnimated:after {
  display: inline-block;
  animation: local-ellipse 2s steps(1) 0s infinite normal none running;
  content: "";
}

@keyframes local-ellipse {
  0%   { content: ''; }
  25%  { content: '.'; }
  50%  { content: '..'; }
  75%  { content: '...'; }
  100% { content: ''; }
}
