.reqResContainer {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  gap: 15px;
  color: var(--activityPrimary);
}

.reqResContainer .header {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  white-space: nowrap;
  font-family: 'Roboto Mono', monospace;
}

.reqResContainer .meta {
  display: flex;
  align-items: center; 
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 45px;
  font-family: 'Roboto Mono', monospace;
}

.reqResContainer .meta .column {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  gap: 10px;
  font-family: 'Roboto Mono', monospace;
}

.reqResContainer .meta .column .columnHeader {
  font-size: 12px;
  color: var(--activityBlackUltraLight);
  font-family: 'Roboto Mono', monospace;
}

.reqResContainer .meta .column .columnValue {
  font-size: 12px;
  font-family: 'Roboto Mono', monospace;
}

.error {
  color: var(--activityRed);
}
