.react-calendar {
  max-width: 100%;
  background: var(--secondary);
  border: 1px solid var(--greyLight);
  border-radius: 8px;
  font-family: 'Muller';
  line-height: 1.125em;
  padding: 15px 7px 10px 7px;
}

.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 3px 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 25px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  color: var(--primary);
}
.react-calendar__navigation button:disabled {
  color: var(--greyMedium);
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--greyMedium);
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__month-view__weekdays__weekday abbr[title] {
  text-decoration: none;
  color: var(--greyLight);
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation__label__labelText--from {
  font-size: 16px;
}
.react-calendar__tile {
  font-family: 'Muller';
  max-width: 100%;
  padding: 7px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  border-radius: 4px;
  transition: all 0.1s ease-in-out;
  color: var(--primary);
}
.react-calendar__tile:disabled {
  opacity: 0.3;
}
.react-calendar__tile:hover {
  background: var(--greyMedium);
  color: var(--primary);
}
.react-calendar__tile--range {
  background: var(--greyMedium);
  color: var(--primary);
  border-radius: 0px;
}

.react-calendar__tile--range:hover {
  background: var(--greyMedium);
  color: var(--primary);
}
.react-calendar__tile--range.react-calendar__tile--rangeStart {
  background: var(--primary);
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  color: var(--secondary);
}
.react-calendar__tile--range.react-calendar__tile--rangeStart:hover,
.react-calendar__tile--range.react-calendar__tile--rangeEnd:hover {
  background: var(--primary);
  color: var(--secondary);
}
.react-calendar__tile--range.react-calendar__tile--rangeEnd {
  background: var(--primary);
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  color: var(--secondary);
}
